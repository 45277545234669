import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, computed, inject, signal } from "@angular/core";
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Organisation, OrganisationSchema } from "../models/organisation.model";


@Injectable({
  providedIn: 'root'
})
export class OrganisationsService {

  httpClient = inject(HttpClient);

  organisations = signal<Organisation[]>([]);
  organisationsCount = computed(() => this.organisations().length);
  currentOrganisationId = signal<number | null>(null);
  currentOrganisation = computed(() => {
    const organisations = this.organisations();
    const currentOrganisationId = this.currentOrganisationId();
    return organisations.find(a => a.id === currentOrganisationId);
  });
  bingMapsEnabled = computed(() => this.currentOrganisation()?.bing);


  constructor() {
    const id = window.localStorage.getItem('orgId');
    if (id !== null) {
      const parsed = parseInt(id);
      if (!isNaN(parsed)) {
        this.currentOrganisationId.set(parsed);
      }
    }
  }

  query() {
    return this.httpClient.get<Organisation[]>(`${environment.ukhabApiUrl}/organisations`).pipe(
      map(response => OrganisationSchema.array().parse(response)),
      tap((r: Organisation[]) => {
        r.sort((a, b) => a.name.localeCompare(b.name));
        this.organisations.set(r);
        const currentId = this.currentOrganisationId();
        // If we have a currentOrganisationId but it is not in the list, set it to null
        if (currentId && !r.some(a => a.id === currentId)) {
          this.currentOrganisationId.set(null);
        }

        if (!this.currentOrganisationId()) {
          this.setOrganisation(r[0].id);
        }
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Loading Org Error:', error);
        return [];
      })
    );
  }

  setOrganisation(id: number) {
    window.localStorage.setItem('orgId', id.toString());
    this.currentOrganisationId.set(id);
  }

}
