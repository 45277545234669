import { z } from 'zod';
import { daforScores, dominScores } from '../services/utils.service';

export const AttachmentSchema = z.object({
  id: z.number(),
  url: z.string(),
  size: z.number()
});

const SpeciesSchema = z
  .object({
    id: z.number(),
    sampleId: z.number().optional(),
    spsScientificName: z.string(),
    spsCommonName: z.string(),
    baselineId: z.number(),
    spsDomin: z.enum(dominScores).optional(),
    spsDafor: z.enum(daforScores).optional(),
    spsNote: z.string().optional(),
    images: AttachmentSchema.array().optional(),
    geometry: z.any().optional()
  })
  .strict();

const SpeciesSamplesSchema = z.object({
  id: z.number(),
  qNum: z.number(),
  qType: z.enum(['quadrat', 'linear']),
  qLength: z.number().optional(),
  qSize: z.number().optional(),
  geometry: z.any().optional(),
  images: AttachmentSchema.array().optional(),
  baselineId: z.number()
}).strict();

const truncateNumber = (num: number, digits = 4): number => {
  const strNum = num?.toString();
  if (!strNum || isNaN(num)) {
    return num;
  }

  const decimalIndex = strNum.indexOf('.');
  if (decimalIndex === -1 || strNum.length - decimalIndex <= digits) {
    return num;
  }

  const truncated = strNum.slice(0, decimalIndex + digits + 1);
  return parseFloat(truncated);
};

const SurveyLogSchema = z
  .object({
    operation: z.string(),
    userId: z.number(),
    createdAt: z.string().datetime({ offset: true }),
    diff: z.array(z.any()).optional(),
    recordId: z.number().optional()
  })
  .strict();

const SurveyLogSchemaWithStyle = SurveyLogSchema.extend({
    name: z.string().optional(),
    style: z.object({
      color: z.string().optional()
    }).default({})
  }).strict();

export const SurveySchema = z
  .object({
    id: z.number(),
    name: z.string().optional(),
    featName: z.string().optional().nullable(),
    ukHabPName: z.string().optional(),
    ukHabPCode: z.string().optional(),
    ukHabCCode: z.string().optional(),
    ukHabS1Cod: z.string().optional(),
    ukHabS1Nam: z.string().optional(),
    ukHabS2Cod: z.string().optional(),
    ukHabS2Nam: z.string().optional(),
    ukHabS3Cod: z.string().optional(),
    ukHabS3Nam: z.string().optional(),
    ukHabS4Cod: z.string().optional(),
    ukHabS4Nam: z.string().optional(),
    ukHabS5Cod: z.string().optional(),
    ukHabS5Nam: z.string().optional(),
    ukHabS6Cod: z.string().optional(),
    ukHabS6Nam: z.string().optional(),
    type: z.string(),
    distinctiv: z.number().optional(),
    conScore: z.any(),
    conResult: z.string().optional(),
    conNotes: z.string().optional(),
    geometry: z.any(),
    area: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    length: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    treeDbh: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    treeSizeClass: z.string().optional(),
    latitude: z.number().optional().transform(t => t ? truncateNumber(t,6) : t),
    longitude: z.number().optional().transform(t => t ? truncateNumber(t,6) : t),
    habNotes: z.string().optional(),
    images: AttachmentSchema.array(),
    metHabType: z.string().optional(),
    metBrdHab: z.string().optional(),
    metHab: z.string().optional(),
    metHabIrr: z.boolean().nullable().optional(),
    metMsg: z.string().optional(),
    userId: z.number(),
    qRecorded: z.number(),
    spsTotal: z.number().optional(),
    spsPerQ: z.number().optional(),
    nativeWSps: z.number().optional(),
    conCrit: z.string().optional(),
    conCrit1: z.any().optional(),
    conCrit2: z.any().optional(),
    conCrit3: z.any().optional(),
    conCrit4: z.any().optional(),
    conCrit5: z.any().optional(),
    conCrit6: z.any().optional(),
    conCrit7: z.any().optional(),
    conCrit8: z.any().optional(),
    conCrit9: z.any().optional(),
    conCrit10: z.any().optional(),
    conCrit11: z.any().optional(),
    conCrit12: z.any().optional(),
    conCrit13: z.any().optional(),
    conCritX: z.any().optional(),
    noteCrit1: z.string().optional(),
    noteCrit2: z.string().optional(),
    noteCrit3: z.string().optional(),
    noteCrit4: z.string().optional(),
    noteCrit5: z.string().optional(),
    noteCrit6: z.string().optional(),
    noteCrit7: z.string().optional(),
    noteCrit8: z.string().optional(),
    noteCrit9: z.string().optional(),
    noteCrit10: z.string().optional(),
    noteCrit11: z.string().optional(),
    noteCrit12: z.string().optional(),
    noteCrit13: z.string().optional(),
    species: SpeciesSchema.array(),
    samples: SpeciesSamplesSchema.array(),
    metBUnits: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    metHUnits: z.number().optional().transform(t => t ? truncateNumber(t) : t),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
    state: z.number(),
    stratSig: z.string().nullable().optional(),
    logs: SurveyLogSchema.array()
  })
  .strict();

export type Survey = z.infer<typeof SurveySchema>;
export type SurveyLog = z.infer<typeof SurveyLogSchema>;
export type SurveyLogWithStyle = z.infer<typeof SurveyLogSchemaWithStyle>;
export type Attachment = z.infer<typeof AttachmentSchema>;
export type Species = z.infer<typeof SpeciesSchema>;
export type SpeciesSample = z.infer<typeof SpeciesSamplesSchema>;
